import React from "react"
import { Link } from "gatsby"
import Magnify_icon from "../../images/magnify-icon.svg"
import ipe_illustration from '../../images/ipe_illustration.png'
import testTube from "../../images/testtube_art.jpg"
import testTubeMobile from "../../images/testtube_art_mobile.jpg"
/* styles */
import "./styles.css"

export default (props) => {

	function goModal(evt) {
		const dataAtt = evt.target.dataset.modal;
		props.goModal(dataAtt);
	}

	return (
		<main className="center-grid mobile-m-b-20">
			<article style={{marginRight:"0px"}} className='endpoints'>
			    {/* <h2 className="cv-headline">IPE & EPA</h2> */}
			    <div className="diff-header-copy">
			        <p className="m-b-20 mobile-m-t-25 cv-body-copy">VASCEPA is icosapent ethyl (IPE): FDA approved to significantly reduce CV risk.<sup>1</sup>
			        </p>
			        <p className="cv-body-copy">The unique molecular structure of prodrug IPE allows for an effective delivery of the active ingredient, which is best absorbed through the small intestine.
			        	<strong> Additionally, IPE has undergone a proprietary purification process which has been approved and validated by the FDA.</strong> This process effectively removes LDL-raising DHA, saturated fats, toxins, and other impurities, leaving only a single purified ingredient.* And, in 2016, the FDA designated this single purified ingredient, IPE, a new chemical entity.<sup>2</sup>
			        </p>
				</div>
				<img className="test-tube" src={props.isMobile ? testTubeMobile : testTube}></img>

			   {/* <div className="chart-container-centering-diff">
			     	<div className="chart-container">
			        	<h4 className="cv-chartheadline">Representation of VASCEPA purification process</h4>
			        	<div className="chartlockup">
			        		<img className="diff-chart" src={ipe_illustration} alt=""/>
			        		<img className="magnify_icon" data-modal="IPE_zoom" src={Magnify_icon} onClick={goModal} alt=""/>
			    		</div>
			    	</div>
			    </div>    */}
			    <div className="endpoints-callouts">
			        <div className="diff-grad-border m-r-10">
			          <p className="callout-copy">
			            This single, purified ingredient is FDA-approved VASCEPA, also known as IPE<span style={{fontWeight:"300"}}>*</span>
			          </p>
			        </div>
			    </div>

		     	<div className="diff-callout">
				    <div className="cta-container m-center fit-content">
				      <h3 className="cta-copy">What type of patient can benefit from VASCEPA?</h3>
				      <Link className="cta-btn" to="/the-vascepa-patient/">Find out</Link>
				    </div>
			    </div>

			    {/*<div className="grid-item-six">
			        <div className="grad-border">
			            <p className="callout-copy"><strong>Updated ADA guidelines now recommend IPE for significant CV risk reduction</strong></p>
			        </div>
			    </div>*/}

			    {/* <div className="grad-border">
			      <p className="callout-copy"><strong>The ADA has updated its guidelines to include IPE as recommended therapy
			    for CV risk reduction.</strong></p>
			    </div> */}

			</article>

			  {/* <article id="section-2" className='endpoints no-bottom-border'>
			      <h2 className="cv-headline">History of VASCEPA
			    </h2>
			    <p className="cv-body">Provide history of VASCEPA development (e.g. began in 2001; broad development program including tox studies, DDI studies, two Phase 3 studies, robust outcomes study; driven by patient care, guided by science)  and vision for why we thought VASCEPA would be successful (unique effects of VASCEPA, large unmet need, hypothesis generated by JELIS, removing DHA avoids LDL increase; depth of ARMN scientific experience science regarding omega-3’s, lipid management and CV disease)</p>
			    <h1 className="FPO-sample">FPO</h1>
			  </article> */}
		</main>
	)
}
