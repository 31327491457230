import React, { Component } from "react"
import { Helmet } from "react-helmet"

import HeroImage from '../components/2.1_vascepa_difference/HeroImage'
import Main from '../components/2.1_vascepa_difference/Main'

import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'

import SEO from "../components/seo"

import Loader from "../components/loader"



export default class VascepaDifference extends Component {
      constructor(props) {
        super(props)
        this.state = {
           stickyNav: false,
           isMobile: false,
           modal:  null
        }
      }

      componentDidMount() {
        let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
        this.setState({ isMobile: bool })
      }

      handleClick(e) {
        // console.log('clicked')
      }

      goModal = (targ) => {
        // console.log('clicked')
        // let str = evt.target.dataset.modal;
        this.setState(() => ({ modal: targ }))

      }

      modalClosed = () => {
        this.setState(() => ({ modal: null }))
      }


      render() {
        return (
          <>
          <Loader>
          <SEO
            title="IPE Is the VASCEPA Difference"
            keywords={[""]}
            description="VASCEPA is icosapent ethyl (IPE): FDA approved to significantly reduce CV risk on top of statins. IPE has undergone a proprietary purification process that has been approved and validated by the FDA."/>
            <HomeModal/>
            <Modal modal={this.state.modal} modalClosed={this.modalClosed}/>
            <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
            <div className="grad"></div>
            <HeroImage onClick={this.handleClick} bckgrdImage={'header2-1'}/>
            <div className="wrapper">
              <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
            </div>
            <ISI scrollPos={this.state.unstick} disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
                <div className="ref-outer">
            <div className="footnotes-container">
              <div className='references'>
                  <p className="cv-footnote hang">*Data on file.</p>
              </div>
            </div>
            <div className="footnotes-container">
              <div className='references'>
                  <p className="cv-footnote"><strong>References: 1. </strong>VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2021.
                  <strong> 2. </strong>US Food and Drug Administration. Vascepa (icosapent ethyl) Capsules (NDA 202057) Exclusivity Determination letter. https://www.fdalawblog.net/wp-content/uploads/archives/docs/VASCEPA%20-%20Exclusivity%20Determination%20on%20Remand.pdf. Published May 31, 2016. Accessed October 6, 2020.
                  <strong> 3. </strong>Mason RP, Sherratt SC. Omega-3 fatty acid fish oil dietary supplements contain saturated fats and oxidized lipids that may interfere with their intended biological benefits. <em>Biochem Biophys Res Commun</em>. 2017;483(1):425-429.
                  </p>
              </div>
            </div>
                </div>
            <Footer />
            </Loader>
          </>
        )
      }
    }
